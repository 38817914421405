import React from 'react';

const About = () => {
  return (
    <>
      <h1>About this App</h1>
      <p>App to search Github users</p>
      <p>Version 1.0.0</p>
    </>
  );
};

export default About;
