import React from 'react';
import Search from '../users/Search';
import Users from '../users/Users';

const Home = () => (
  <>
    <Search />
    <Users />
  </>
);

export default Home;
